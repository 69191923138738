import { useFetcher } from '@remix-run/react';
import { useMemoizedFn } from 'ahooks';
import { useEffect, useRef } from 'react';
import { removeLocalFavouritedItems } from '~/modules/design-board/util/design-board-utils';
import type { UserLogoutAction } from '~/routes/remix-api.user.logout';

export const useLogout = (redirectUrl?: string) => {
    const logoutFetcher = useFetcher<UserLogoutAction>();
    const logoutSubmitted = useRef(false);

    const onLogoutClick = useMemoizedFn(() => {
        logoutFetcher.submit(redirectUrl ? { redirectUrl } : {}, {
            method: 'post',
            action: `/remix-api/user/logout`,
        });
        logoutSubmitted.current = true;
    });

    const logoutSideEffects = useMemoizedFn(() => {
        removeLocalFavouritedItems();
        window.location.reload();
    });

    useEffect(() => {
        if (!logoutSubmitted.current || logoutFetcher.state !== 'idle') return;
        logoutSubmitted.current = false;
        window.location.reload();
    }, [logoutFetcher.state]);

    return { onLogoutClick, logoutFetcher, logoutSideEffects };
};
